var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getCertificate, statusToken } from "./sislam_assinador_hw";
import { Document } from "./document";
import PQueue from 'p-queue';
import axios from 'axios';
import { EventEmitter } from 'events';
export var SislamAssinador;
(function (SislamAssinador_1) {
    class SislamAssinador {
        constructor(assinador_url) {
            this.sislamAssinadorURL = '';
            this.sislamAssinadorAuthToken = '';
            this.webEID = false;
            this.sislamWeb = axios.create({
                timeout: 120000,
            });
            this.sislamAssinadorURL = assinador_url;
            this.eventEmitter = new EventEmitter();
        }
        on(ev, cb) {
            return this.eventEmitter.on(ev, cb);
        }
        checkWebID() {
            return this.setWebID();
        }
        setWebID() {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    if (window.localStorage.getItem('sislam.webeid') !== null) {
                        this.eventEmitter.emit('info', { message: 'plugin loaded', code: 'plugin_loaded' });
                        return this.webEID = true;
                    }
                    let status_token = yield statusToken();
                    if (status_token === undefined) {
                        return;
                    }
                    ;
                    this.webEID = true;
                    window.localStorage.setItem('sislam.webeid', JSON.stringify(status_token));
                    this.eventEmitter.emit('info', { message: 'plugin loaded', code: 'plugin_loaded' });
                }
                catch (error) {
                    this.webEID = false;
                    this.eventEmitter.emit('failure', { message: 'no plugin founded', error: error, code: 'no_plugin_founded' });
                    window.localStorage.removeItem('sislam.webeid');
                }
            });
        }
        signDocuments(urls) {
            return __awaiter(this, void 0, void 0, function* () {
                let errorCount = 0;
                const status = yield statusToken;
                const sislamAssinador = axios.create({
                    baseURL: this.sislamAssinadorURL,
                    timeout: 120000,
                });
                const queueSislam = new PQueue({ concurrency: 3 });
                const queueAssinador = new PQueue({ concurrency: 1, interval: 1000 });
                const eventEmitter = this.eventEmitter;
                queueSislam.on('error', (error) => {
                    eventEmitter.emit('error', { message: 'document error', stack: error, code: 'document_error' });
                });
                queueAssinador.on('error', (error) => {
                    eventEmitter.emit('error', { message: 'sign document error', stack: error, code: 'sign_document_error' });
                });
                if (!status) {
                    return;
                }
                const certificate = yield getCertificate();
                if (certificate === undefined) {
                    eventEmitter.emit('failure', { message: 'certificate not selected', code: 'no_certificate' });
                    throw ('Erro ao pegar o certificado');
                }
                const certificatePem = "-----BEGIN CERTIFICATE-----\n" + certificate.certificate + "\n-----END CERTIFICATE-----";
                eventEmitter.emit('info', { message: 'certificate selected', code: 'certificate_selected' });
                urls.forEach(url => {
                    queueSislam.add(() => __awaiter(this, void 0, void 0, function* () {
                        const document = new Document(url, {
                            sislamWeb: this.sislamWeb,
                            sislamAssinador: sislamAssinador
                        });
                        let documentisValid = yield document.checkDocument();
                        if (!documentisValid) {
                            throw ('Erro documento inválido');
                        }
                        queueAssinador.add(() => __awaiter(this, void 0, void 0, function* () {
                            const prepareData = yield document.prepareToSign(certificatePem);
                            eventEmitter.emit('info', { message: 'document prepared', url: url, code: 'prepared_document' });
                            const sigDocData = yield document.sign(certificate);
                            eventEmitter.emit('signed', { message: 'document signed', document: document.informations, code: 'signed_success' });
                        }));
                    }));
                });
                yield queueSislam.onIdle();
                yield queueAssinador.onIdle();
            });
        }
    }
    SislamAssinador_1.SislamAssinador = SislamAssinador;
})(SislamAssinador || (SislamAssinador = {}));
window.SislamAssinador = SislamAssinador;
