// Hex to Base64 
const hexToBase64 = (hexstring) => {
    let base64 = btoa(hexstring.match(/\w{2}/g).map((a) => {
        return String.fromCharCode(parseInt(a, 16));
    }).join(''));
    return base64;
};
// Base64 to Hex
const base64ToHex = (str) => {
    for (var i = 0, bin = atob(str.replace(/[ \r\n]+$/, "")), hex = []; i < bin.length; ++i) {
        let tmp = bin.charCodeAt(i).toString(16);
        if (tmp.length === 1)
            tmp = "0" + tmp;
        hex[hex.length] = tmp;
    }
    return hex.join("");
};
export { hexToBase64, base64ToHex };
