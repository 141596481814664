var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { signDocument } from "./sislam_assinador_hw";
import { base64ToHex } from './utils/hexB64';
class Document {
    constructor(url, apis) {
        this.documentUuid = '';
        this.documentUrl = '';
        this.secureCode = '';
        this.digest = '';
        this.documentUrl = url;
        this.apis = apis;
    }
    getSislamInformation() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.apis.sislamWeb.get(this.documentUrl, {});
        });
    }
    getInformation() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.apis.sislamAssinador.get('documents/' + this.documentUuid + ".json", { headers: { Authorization: this.authToken } });
        });
    }
    prepareToSign(certificate) {
        return __awaiter(this, void 0, void 0, function* () {
            const prepareData = yield this.apis.sislamAssinador.put("documents/" + this.documentUuid + '/prepare_to_sign.json', { certificate: certificate }, { headers: { Authorization: this.authToken } });
            if (prepareData.data.document.digest === null) {
                throw ('Erro ao preparar documento para assinatura');
            }
            this.secureCode = prepareData.data.document.secure_code;
            this.digest = prepareData.data.document.digest;
            return prepareData.data.document;
        });
    }
    sign(certificate) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const signature = yield signDocument(certificate, this.digest);
            const signatureResponse = yield this.apis.sislamAssinador.put("documents/" + this.documentUuid + '/sign.json', { signature: base64ToHex(signature), secure_code: this.secureCode }, { headers: { Authorization: this.authToken } });
            if (signatureResponse.status < 200 || signatureResponse.status > 205) {
                throw ('Erro ao assinar documento');
            }
            this.informations.signature_body = signatureResponse.data;
            this.informations.auth_token = this.authToken;
            console.log(signatureResponse);
            if (this.informations.signature_body === null || this.informations.signature_body === undefined) {
                throw ('Erro ao assinar documento');
            }
            if (this.informations.signature_body.document === null || this.informations.signature_body.document === undefined) {
                throw ('Erro ao assinar documento');
            }
            if (this.informations.signature_body.document.signed_by === null || this.informations.signature_body.document.signed_by === undefined) {
                throw ('Erro ao assinar documento');
            }
            const tokenDom = (_a = document.querySelector("meta[name=csrf-token]")) === null || _a === void 0 ? void 0 : _a.getAttribute('content');
            const signatureSislamResponse = yield this.apis.sislamWeb.put('/sign_documents/' + this.documentUuid, signatureResponse.data, {
                headers: {
                    "X-CSRF-Token": tokenDom
                }
            });
            return true;
        });
    }
    checkDocument() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const sislamDoc = yield this.getSislamInformation();
                this.documentId = sislamDoc.data.id;
                this.documentUuid = sislamDoc.data.uuid;
                this.authToken = sislamDoc.data.auth_token;
                this.informations = { id: this.documentId, url: this.documentUrl, uuid: this.documentUuid };
                if (this.documentUuid === null || this.documentUuid === undefined) {
                    throw ('Erro ao enviar documento para assinatura, verifique a sua conexão e tente novamente');
                }
                const AssinadorDoc = yield this.getInformation();
                if (AssinadorDoc === null || this.documentUuid === undefined) {
                    throw ('Erro ao enviar documento para assinatura, verifique a sua conexão e tente novamente');
                }
                if (AssinadorDoc.data.document.uuid === null || AssinadorDoc.data.document.uuid === undefined) {
                    throw ('Erro ao enviar documento para assinatura, verifique a sua conexão e tente novamente');
                }
                return sislamDoc;
            }
            catch (error) {
                console.log(error);
                return false;
            }
        });
    }
}
export { Document };
