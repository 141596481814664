var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { status, getSigningCertificate, sign } from '@web-eid/web-eid-library/web-eid';
import { hexToBase64 } from './utils/hexB64';
const lang = 'pt-BR';
const hashFunction = 'SHA-256';
// const userInteractionTimeout = 10 * 60 * 100000 // 10 minutes
const actionOptions = {
    lang: lang,
    // userInteractionTimeout: userInteractionTimeout
};
const getCertificate = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const certificate = yield getSigningCertificate(actionOptions);
        return certificate;
    }
    catch (error) {
    }
});
const signDocument = (certificate, hashHex) => __awaiter(void 0, void 0, void 0, function* () {
    const hash = hexToBase64(hashHex);
    const { signature } = yield sign(certificate.certificate, hash, hashFunction, actionOptions);
    return signature;
});
const statusToken = () => {
    return status();
};
export { getCertificate, signDocument, statusToken };
